<template>
    <div class="marketing-popup-modal-wrap" v-if="openCheck">
        <div class="marketing-popup-modal">
            <div class="marketing-popup-modal-header">
                <label class="winex-checkbox" @click="closeModalToday"><input type="checkbox" name="today-popup-view"
                                                                              value="1"><span>오늘 하루 그만보기</span></label>
                <div class="close-btn" @click="closeModal"></div>
            </div>
            <div class="modal-content-wrap">
                <div class="modal-image-wrap">
                    <img :src="pcImage" class="pc-image">
                    <img :src="mobileImage" class="mobile-image">
                </div>
                <div class="modal-btn-wrap" v-if="useButton">
                    <button class="winex-btn" :class="buttonClass" @click="bottomButtonEvent">{{buttonContent}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            buttonClass: {
                type: String,
                default: "primary-btn"
            },
            buttonContent: {
                type: String
            },
            useButton: {
                default: true
            },
            pcImage: {
                required: true
            },
            mobileImage: {
                required: true
            },
            modalName: {
                required: true
            },
            link: {
                type: String
            }
        },
        data() {
            return {
                openCheck: true
            }
        },
        created() {
            const today = new Date().getTime()
            if (today < localStorage.getItem(this.modalName)) {
                this.openCheck = false
            }
        },
        methods: {
            closeModalToday() {
                this.openCheck = false
                const today = new Date();
                const tomorrow = new Date(today)
                tomorrow.setDate(today.getDate() + 1)
                localStorage.setItem(this.modalName, tomorrow.getTime())
            },
            closeModal() {
                this.openCheck = false
            },
            bottomButtonEvent() {
                this.$router.push(this.link)
            }
        },
        name: "PopupModal"
    }
</script>

<style scoped lang="scss">

    .modal-btn-wrap {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
    .marketing-popup-modal {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .marketing-popup-modal-header {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        height: 48px;

        .winex-checkbox {
            display: flex;
            align-items: center;

            span {
                color: #FFF;
            }
        }

        .close-btn {
            width: 48px;
            height: 48px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(~@/assets/images/marketing/close-btn.png);
            cursor: pointer;
        }
    }

    @media (max-width: 768px) {
        .pc-image {
            display: none;
        }
        
        .modal-image-wrap {
            max-height: 80vh;
            overflow-y: auto;
        }
    }

    @media (min-width: 769px) {
        .mobile-image {
            display: none;
        }

    }
</style>