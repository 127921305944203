<template>
    <div class="wine-scroll-wrap">

        <ul class="wine-scroll-list">
            <!--<li class="wine-scroll-item" v-for="wineInfo in wineList" v-bind:key="wineInfo.key" @click="$router.push(`/market/detail/${wineInfo.key}`)">-->
            <li class="wine-scroll-item" v-for="wineInfo in wineList" v-bind:key="wineInfo.key" @click="$router.push(`/market/detail/${wineInfo.market_key}/${wineInfo.nft_key}`)">
                <div class="wine-scroll-card">
                    <div class="wine-image" :style="{backgroundImage:`url(${wineInfo.wine_image})`}"></div>
                    <div class="wine-content-wrap">
                        <!--<p class="wine-brand">{{wineInfo.brand}}</p>-->
                        <h4 class="wine-title">{{wineInfo.wine_name}} </h4>
                        <div class="wine-price-wrap">
                            <div class="wine-price-title">판매 가격</div>
                            <div class="wine-price">
                                <span class="coin-price">{{$numberFormat(parseInt(wineInfo.last_price)/1000000)}}</span>&nbsp;
                                <span class="won-price">({{$numberFormat(contract.toWon(parseInt(wineInfo.last_price)/1000000)).split('.')[0]}}원)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</template>

<script>
    import getContractApi from "../../api/contract"
    export default {
        name: "WineScroll",
        props: {
            wineList:{required:true}
        },
        methods: {

        },
        created() {
            this.contract = getContractApi();
        },
        data() {
            return {
                contract: null
            }
        },
        mounted() {

        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/scss/wine-scroll.scss";
</style>

