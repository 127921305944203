<template>
    <div>
        <Main></Main>
        <CountIntro class="home-count-info" :wine-info="homeWineInfo"></CountIntro>
        <div class="wine-scroll-outer-wrap">
            <div class="title">Fine Wine NFT</div>
            <WineScroll :wine-list="fineWineList"></WineScroll>

        </div>

        <BestSlider></BestSlider>
        <div class="content-wrap">
            <div class="about-advantage-box">
                <div class="title">
                    Advantages of Winex
                </div>
                <div class="grid-wrap">
                    <div class="grid-items">
                        <div class="item" v-for="advantage in advantageList" v-bind:key="advantage.title">
                            <div class="icon">
                                <img :src="advantage.icon"/>
                            </div>
                            <div class="subject subject-pc">
                                {{advantage.title}}
                            </div>
                            <div class="subject subject-mo">
                                {{advantage.titleMo}}
                            </div>
                            <div class="text">
                                {{advantage.text}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="round-box">
                <div class="why-winex-box">
                    <div class="graph-img"><img src="~@/assets/images/about-graph.png"/></div>
                    <div class="textarea">
                        <div class="title">
                            Why Winex
                        </div>
                        <div class="text">
                            <p class="bold">파인 와인은 투자 가치가 있는 안전자산입니다</p>
                            <p>
                                파인 와인은 소비량에 비해 공급량이 항상 부족하고 시간이 흐를수록 맛과 향 그리고 고유의 가치가 높아집니다. 주식은 발행량이 늘어나지만, 와인은 같은 빈티지를 마시면 점점 줄어들기 때문에 더욱 가치가 상승합니다. 또한 희소성 때문에 매년 가격이 우상향하므로 높은 수익이 기대되고 규격화되어 있는 아파트와 유사한 안전자산입니다.<br/>
                                파인 와인의 투자가치는 이미 역사적으로 입증 되었으며, 그동안 어려웠던 파인 와인 투자를 최신 NFT 기술로 편리하게 해보세요.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-gray">
            <div class="how-it-works">
                <div class="title">
                    How it Works
                </div>
                <div class="content">
                    <div class="content-pc">
                        <div class="image">
                            <img src="~@/assets/images/how-it-works20220720.png"/>
                        </div>
                    </div>
                    <div class="content-tablet">
                        <img src="~@/assets/images/tablet-how-it-works20220720.png">
                    </div>
                    <div class="content-mobile">
                        <img src="~@/assets/images/mobile-how-it-works.png">
                        <div class="winex-btn primary-btn h40 w120" @click="moveHowItWorks">더보기</div>
                    </div>
                </div>
                <!--<div class="arrow-guide">
                    우측으로 스크롤
                    <img src="~@/assets/images/icon/ic-arrow-right-location.png"/>
                </div>-->
            </div>
        </div>
        <div class="content-wrap">
            <div class="faq">
                <div class="title">
                    FAQ
                </div>
                <div class="content">
                    <Accordion v-for="item in faqList" :no="item.faq_no" :image-src="item.faq_image" :title="item.faq_title" :content="item.faq_content" :category="'일반'" v-bind:key="item.faq_no"></Accordion>
                    <div class="btn-wrap">
                        <router-link :to="{path: `/cs/faq`}">
                            <DefaultButton
                                    :class-list="'primary-btn h40 w120'"
                                    :content="'더보기'"
                            ></DefaultButton>
                        </router-link>

                    </div>
                </div>
            </div>
        </div>
        <MembershipInfo></MembershipInfo>
        <div class="container-gray" style="margin-top: 0 !important;">
            <div class="content-wrap">
                <div class="winex-team">
                    <div class="title">
                        Winex Team
                    </div>
                    <div class="content">
                        <div class="grid-items">
                            <div class="item" v-for="teamMember in teamList" v-bind:key="teamMember.name">
                                <div class="image" :style="teamMember.image"></div>
                                <div class="text">
                                    <div class="name">
                                        {{teamMember.name}}
                                    </div>
                                    <div class="rank">
                                        {{teamMember.rank}}
                                    </div>
                                    <ul class="feature">
                                        <li v-for="feature in teamMember.featureList" v-bind:key="feature" v-html="feature"></li>
                                    </ul>
                                </div>
                            </div><!--/item-->
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- end team -->
        <div class="content-wrap">
            <div class="partners">
                <div class="title">
                    Winex Partners
                </div>
                <div class="content">
                    <div class="grid-items">
                        <div class="item">
                            <img src="~@/assets/images/partners/dhl.png"/>
                        </div>
                        <div class="item">
                            <img src="~@/assets/images/partners/jiran.png"/>
                        </div>
                        <div class="item">
                            <img src="~@/assets/images/partners/decent.png"/>
                        </div>
                        <div class="item">
                            <img src="~@/assets/images/partners/axa.png"/>
                        </div>
                        <div class="item">
                            <img src="~@/assets/images/partners/primus.jpg"/>
                        </div>
                        <div class="item">
                            <img src="~@/assets/images/partners/JNGALLERY.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- 팝업 모달 시작 -->
        <div class="marketing-popup-modal-wrap" v-if="marketingPopup">
            <div class="marketing-popup-modal">
                <div class="marketing-popup-modal-header">
                    <label class="winex-checkbox" @click="closeMarketingPopupToday"><input type="checkbox" name="today-popup-view" value="1"><span>오늘 하루 그만보기</span></label>
                    <div class="close-btn" @click="closeMarketingPopup"></div>
                </div>
                <div class="marketing-popup-modal-content-wrap">
                    <div class="inner-content">
                        <div class="logo"></div>

                        <h2 class="marketing-top-content pc">이제 민팅 소식을 더 쉽고 편하게 받아보세요!</h2>
                        <h2 class="marketing-top-content mobile">이제 민팅 소식을<br/>더 쉽고 편하게 받아보세요!</h2>

                        <h3 class="marketing-mid-content pc">
                            입력하신 이메일을 통해 다음 민팅 일정을 안내드립니다.<br/>
                            마케팅 정보 수신에 동의하고,와넥스의 유용한 정보를 편하게 만나 보세요!
                        </h3>
                        <h3 class="marketing-mid-content mobile">
                            입력하신 이메일을 통해 다음 민팅 일정을 안내드립니다.
                            마케팅 정보 수신에 동의하고,와넥스의 유용한 정보를 편하게 만나 보세요!
                        </h3>

                        <p class="marketing-content-wrap">
                            <span class="marketing-content-title">Winex. 마케팅 수신채널 동의</span><br/>
                            Winex. 에서는 본 수신에 동의하시는 회원님께 각종 이벤트 정보 및 NFT 관련 멤버쉽 서비스 등 다양한 혜택과 정보를 담아 안내해 드리고 있습니다.<br/>
                            <br/>
                            1. 수신채널: 이메일, 전화번호<br/>
                            2. 수신에 동의하지 않으셔도 Winex. 서비스의 이용은 가능하며, 추후 수신을 원하지 않을 경우 Winex. 고객센터로 문의주십시오
                        </p>

                        <input type="text" class="winex-input" placeholder="알림 받으실 이메일을 입력해주세요" v-model="marketingEmail" @keyup="sendMarketingMailKey">
                        <button class="winex-btn primary-btn popup-confirm-btn" @click="sendMarketingMail">동의 후 민팅소식 받기</button>
                    </div>
                    <div class="back-gradient"></div>
                </div>
            </div>
        </div>
        <!-- 팝업 모달 끝 -->

        <!-- 임시 팝업 + API 세팅 되는지 테스트 -->
        <PopupModal
        :use-button="popup.use_button"
        :modal-name="'popup_no_'+popup.popup_no"
        :mobile-image="popup.mobile_image_path"
        :pc-image="popup.pc_image_path"
        v-bind:key="popup.popup_no"
        v-for="popup in popupList"
        >

        </PopupModal>

    </div>
</template>
<script>
    import BestSlider from "../../components/home/BestSlider";
    import Main from "../../components/home/Main";
    import CountIntro from "../../components/home/CountIntro";
    import Accordion from "../../components/Accordion";
    import DefaultButton from "../../components/buttons/DefaultButton";
    import WineScroll from "../../components/home/WineScroll";
    import MembershipInfo from "../../components/home/MembershipInfo";
    import PopupModal from "../../components/popup/PopupModal";
    export default {
      name: 'Index',
      components: {
          PopupModal,
          MembershipInfo,
          WineScroll,
          DefaultButton,
          Accordion,
          CountIntro,
          Main,
          BestSlider

      },
        created() {
            let self = this
            const today = new Date().getTime()
            if(today < localStorage.getItem('marketingPopup')) {
                this.marketingPopup = false
            }

            this.$store.dispatch('faq/getFaqList').then((data) => {
                let maxLen = 6
                if(data.length < 6) {
                    maxLen = data.length
                }
                for(let i = 0; i < maxLen; i++) {
                    this.faqList.push(data[i])
                }
            })

            this.$store.dispatch('popup/getPopupList', {open_state: 1}).then((data) => {
                self.popupList = data
            });

            this.$store.dispatch('fineWineList/getFineWineList').then((data) => {
                self.fineWineList = data
            })

            this.$store.dispatch('homeWineInfo/getBestWineList').then((data) => {
                self.homeWineInfo = data
            })
        },
        data () {
            return  {
                bestWineList:[],
                fineWineList:[],
                homeWineInfo:[],
                popupList: [],
                faqList: [

                ],
                wineList: [
                    {
                        status:1,
                        brand:'Domaine Comte Georges de Vogue',
                        name:'Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019',
                        key:'10',
                        price: {
                            coin:1136,
                            won:'1,475,834'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220508/45ceb1e85eda6.png"
                    },
                    {
                        status:1,
                        brand:'Domaine Comte Georges de Vogue',
                        name:'Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2019',
                        key:'4',
                        price: {
                            coin:951,
                            won:'1,235,491'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220509/e4ce616d1a5be.png"
                    },
                    {
                        status:1,
                        brand:'Domaine de La Romanee-Conti',
                        name:'Domaine de La Romanee-Conti La Tache Grand Cru 2017',
                        key:'5',
                        price: {
                            coin:6627,
                            won:'8,609,463'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220523/6dc24ad72b88d.png"
                    },
                    {
                        status:1,
                        brand:'Domaine de La Romanee-Conti',
                        name:'Domaine de La Romanee-Conti Echezeaux Grand Cru 2018',
                        key:'7',
                        price: {
                            coin:3808,
                            won:'4,947,161'
                        },
                      image: "https://cdn.imweb.me/thumbnail/20220508/b49d735daabe1.png"
                    },
                    {
                        status:1,
                        brand:'Louis Roederer',
                        name:'Louis Roederer Cristal 2009 1.5L Magnum',
                        key:'8',
                        price: {
                            coin:602,
                            won:'782,088'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220508/7f917b103c3d5.jpg"
                    },
                    {
                        status:3,
                        brand:'Pierre Girardin',
                        name:'Pierre Girardin Batard-Montrachet Grand Cru 2020',
                        key:'9',
                        price: {
                            coin:631,
                            won:'819,764'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220508/13fd3fad4419c.png"
                    },
                ],
                teamList: [
                    {
                        image:{backgroundImage: `url(${require('@/assets/images/char/member01.png')})`},
                        name:"Charles Chahyoung Lee",
                        rank:"CEO, Founder",
                        featureList:[
                            "Yonsei Univ. Computer Science.",
                            "Serial Entrepreneur.",
                            "CEO&Founder of Playforum,<br/> Thatwine and Luolle"
                        ]
                    },
                    {
                        image:{backgroundImage: `url(${require('@/assets/images/char/member02.png')})`},
                        name:"Insoon Lee",
                        rank:"Winex Council for fine Wine",
                        featureList:[
                            "Yonsei Univ. Eng literature. Ph.D",
                            "Director of LeeInSoon Winelab",
                            "Former Director of WSA Academy",
                            "Founder of WSET in Korea"
                        ]
                    },
                    {
                        image:{backgroundImage: `url(${require('@/assets/images/char/member03.png')})`},
                        name:"Yunjin Choi",
                        rank:"Winex Council for fine Wine",
                        featureList:[
                            "Kyunghee Univ. Ph.D",
                            "Director of Vinojin company",
                            "Vice President of KISA",
                            "Adjunct Professor, Wine and<br/>Sommelier, Kyunghee Univ"
                        ]
                    },
                    {
                        image:{backgroundImage: `url(${require('@/assets/images/char/member04.png')})`},
                        name:"Coming Soon",
                        rank:"",
                        featureList:[

                        ]
                    },
                    {
                        image:{backgroundImage: `url(${require('@/assets/images/char/member05.png')})`},
                        name:"ChiYoung Oh",
                        rank:"Seed Investor & Incubator",
                        featureList:[
                            "CNU Computer Science",
                            "Jiran CDO & Founder",
                            "jiran.com"
                        ]
                    },
                    {
                        image:{backgroundImage: `url(${require('@/assets/images/char/member06.png')})`},
                        name:"SangSu Baek",
                        rank:"Seed Investor & Incubator",
                        featureList:[
                            "CNU Computer Science Ph.D",
                            "D'CENT CEO",
                            "dcentwallet.com"
                        ]
                    },

                    {
                        image:{backgroundImage: `url(${require('@/assets/images/char/member07.png')})`},
                        name:"Jaeyeol Ha",
                        rank:"Seed Investor & PM",
                        featureList:[
                            "SNU EECS Ph.D",
                            "Balancepole CEO"
                        ]
                    },
                    {
                        image:{backgroundImage: `url(${require('@/assets/images/char/member08.png')})`},
                        name:"Seongsoo Moon",
                        rank:"Development Partners",
                        featureList:[
                            "HKNU Computer Science.",
                            "Zzieut CEO & Founder",
                            "zzieut.com"
                        ]
                    }
                ],
                advantageList: [
                    {
                        icon:require("@/assets/images/icon/ic-wine.png"),
                        title:"프랑스 현지의 체계적인 와인보관",
                        titleMo:"현지의 체계적인 와인보관",
                        text:"투자가치 높은 파인와인을 프랑스의 와넥스 힐레브란트 창고에 보관합니다. 와인 전문 창고이므로 최적의 보관상태를 유지합니다."
                    },
                    {
                        icon:require("@/assets/images/icon/ic-database-t1.png"),
                        title:"합리적이고 경쟁력 있는 가격",
                        titleMo:"합리적이고 경쟁령 있는 가격",
                        text:"프랑스 수출가로 구매 가능하기 때문에 경쟁력과 투자 가치가 있습니다."
                    },
                    {
                        icon:require("@/assets/images/icon/ic-truck-t1.png"),
                        title:"와인의 실물 배송",
                        titleMo:"와인의 실물 배송",
                        text:"실물 와인은 NFT 홀더가 리딤 Redeem 요청을 하면 해당 와인을 언제 어디로든 원하는 장소로 배송받을 수 있습니다."
                    },
                    {
                        icon:require("@/assets/images/icon/ic-paper-check.png"),
                        title:"확실한 보증 시스템",
                        titleMo:"확실한 보증 시스템",
                        text:"와넥스의 파인 와인 NFT는 모두 와넥스의 창고에 보관 후 발행하며, 마지막 NFT 홀더가 리딤 요청을 하면 해당 와인을 배송받기에 완벽한 보증이 됩니다."
                    },
                    {
                        icon:require("@/assets/images/icon/ic-atom-t1.png"),
                        title:"다양하고 희소성 높은 소싱력",
                        titleMo:"다양하고 희소성 높은 소싱력",
                        text:"와넥스 플랫폼이 보증하는 프랑스 현지 와이너리와 네고시앙이 직접 수출가에 판매하여 일반적으로 구하기 힘든 파인 와인들을 손쉽게 구매/거래/판매 할 수 있습니다."
                    },
                    {
                        icon:require("@/assets/images/icon/ic-currency-eth-t1.png"),
                        title:"쉽고 빠른 그리고 안전한 거래",
                        titleMo:"쉽고 빠른, 안전한 거래",
                        text:"실물와인 거래에서 겪는 어려움인 진위 여부및 적정 보관 여부를 와넥스 플랫폼에서는 NFT거래만으로 해결 할 수 있습니다."
                    }
                ],
                marketingPopup: true,
                marketingEmail:''
            }
        },
        methods: {
            closeMarketingPopup() {
                this.marketingPopup = false
            },
            closeMarketingPopupToday() {
                this.marketingPopup = false
                const today = new Date();
                const tomorrow = new Date(today)
                tomorrow.setDate(today.getDate() + 1)
                localStorage.setItem('marketingPopup',tomorrow.getTime())
            },
            sendMarketingMail() {
                let mail_format =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if(this.marketingEmail.match(mail_format)) {

                    this.$store.dispatch('membershipMail/appendMembershipMail', {mail:this.marketingEmail}).then(() => {
                        alert('Winex 마케팅 수신채널에 동의하셨습니다.')
                        this.marketingPopup = false
                    }).catch((e) => {
                        if(e.status === 409) {
                            alert('이미 등록된 메일 입니다.')
                        } else if(e.status === 405) {
                            alert('정확한 이메일을 입력해 주세요.')
                        }
                    })
                } else {
                    alert('정확한 이메일을 입력해 주세요.')
                }

            },
            sendMarketingMailKey(e) {
                if(e.keyCode === 13) {
                    this.sendMarketingMail()
                }
            },
            moveHowItWorks() {
                this.$router.push('/cs/faq?no=9')
            }
        }
    }
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/common";
    .home-count-info {
        @media (min-width: 1200px) {
            margin-top: 80px;
            margin-bottom: 80px;
        }
        @media (max-width: 1200px) and (min-width: 769px){
            margin-top: 40px;
            margin-bottom: 40px;
        }
        @media (max-width: 768px) {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

@import "@/assets/scss/about.scss";
    .faq {
        padding: 80px 0;
        @media (max-width: 1199px) {
            padding: 40px 0;
        }
        .title {
            width: 100%; text-align: center;
            font-family: 'RegularBoldCello-Bold';
            color: $dark_light;
            font-size: 55px;
            margin-bottom: 40px;
            @media (max-width: 1199px) {
                margin-bottom: 20px;
                font-size: 32px;
            }
        }
        .btn-wrap {
            text-align: center;
            margin-top: 50px;
            margin-bottom: 90px;
            @media (max-width: 1199px) {
                margin: 40px 0;
            }
        }
    }
    .wine-scroll-outer-wrap {
        margin-bottom: 50px;
        @media (max-width: 1199px) {
            margin-bottom: 20px;
        }
        .title {
            width: 100%; text-align: center;
            font-family: 'RegularBoldCello-Bold';
            color: $dark_light;
            font-size: 55px;
            margin-bottom: 50px;
            @media (max-width: 1199px) {
                font-size: 32px;
                margin-bottom: 20px;
            }
        }
    }

    @media (min-width: 1200px) {
        .round-box {
            margin-top: 100px !important;
        }
    }
    @media (max-width: 1199px) {
        .round-box {
            margin-top: 0 !important;
        }
    }

    /* Marketing Popup */
    .marketing-popup-modal-wrap {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.4);
        z-index: 100;
        @media (max-width: 768px) {
            overflow-y: auto;
        }
        .marketing-popup-modal {
            width: 560px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            @media (max-width: 768px) {
                width: 328px;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                padding: 40px 0;
            }
            .marketing-popup-modal-header {
                display: flex;
                justify-content: flex-end;
                gap: 20px;
                height: 48px;
                .winex-checkbox {
                    display: flex;
                    align-items: center;
                    span {
                        color: #FFF;
                    }
                }
                .close-btn {
                    width: 48px;
                    height: 48px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url(~@/assets/images/marketing/close-btn.png);
                    cursor: pointer;
                }
            }

            .marketing-popup-modal-content-wrap {
                text-align: center;
                border-radius: 10px;
                position: relative;

                @media (min-width: 769px) {
                    background-image: url(~@/assets/images/marketing/popup_pc_back.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    .inner-content {
                        position: relative;
                        z-index: 10;
                        padding: 40px;
                    }

                    .back-gradient {
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        width: 100%;
                        height: 400px;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                        border-radius: 10px;
                    }
                    .mobile {
                        display: none;
                    }
                    .logo {
                        width: 158px;
                        height: 40px;
                        background-image: url(~@/assets/images/marketing/pc-logo.png);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        display: inline-block;
                    }

                    .marketing-top-content {
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 48px;
                        color: #FFF;
                        letter-spacing: -0.04em;
                        margin-bottom: 10px;
                    }

                    .marketing-mid-content {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 28px;
                        letter-spacing: -0.02em;
                        color: #FFF;
                        margin-bottom: 40px;
                    }

                }
                @media (max-width: 768px) {
                    background-image: url(~@/assets/images/marketing/popup_mobile_back.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    .inner-content {
                        position: relative;
                        z-index: 10;
                        padding: 40px;
                    }

                    .back-gradient {
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        width: 100%;
                        height: 350px;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                        border-radius: 10px;
                    }
                    .pc {
                        display: none;
                    }
                    .logo {
                        width: 119px;
                        height: 30px;
                        background-image: url(~@/assets/images/marketing/mobile-logo.png);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        display: inline-block;
                    }

                    .marketing-top-content {
                        font-family: 'SUIT';
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 36px;
                        color: #FFF;
                        letter-spacing: -0.02em;
                        margin-bottom: 10px;
                    }

                    .marketing-mid-content {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 28px;
                        letter-spacing: -0.02em;
                        color: #FFF;
                        margin-bottom: 40px;
                    }
                    .popup-confirm-btn {
                        width: 100% !important;
                    }
                }

                .marketing-content-wrap {
                    font-family: 'SUIT';
                    text-align: left;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: -0.02em;
                    color: $light_light_dark;
                    margin-bottom: 40px;
                    .marketing-content-title {
                        font-family: 'SUIT';
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 24px;
                        color: $light_light_dark;
                    }
                }
                .winex-input {
                    margin-bottom: 40px;
                }


            }

        }
    }

    /*.membership-minting-popup-modal-wrap {

        .inner-content {
            background-color: #000;
            background-image: none !important;
            border-radius: 10px;
            overflow: hidden;
            @media (max-width: 768px) {
                padding: 20px !important;
            }
            .back-top-image {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 169px;
                height: 211px;
                background-image: url(~@/assets/images/minting-popup/pc/back-top.png);
                position: absolute;
                top: 0;
                right: 0;
                @media (max-width: 768px) {
                    display: none;
                }
            }
            .back-bottom-image {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 158px;
                height: 258px;
                background-image: url(~@/assets/images/minting-popup/pc/back-bottom.png);
                position: absolute;
                bottom: 22px;
                left: 0;
                @media (max-width: 768px) {
                    display: none;
                }
            }
            .inner-inner-content {
                z-index: 10;
                position: relative;
                .back-gradient-membership {
                    @media (max-width:768px) {
                        width: 100%;
                        height: 187px;
                        position: absolute;
                        z-index: 9;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-image: url(~@/assets/images/minting-popup/mobile/back-gradient.png);
                    }
                }
            }
            .logo-wrap {
                width: 158px;
                height: 40px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(~@/assets/images/minting-popup/pc/logo.png);
                margin: 0 auto 4px auto;
                z-index: 10;
                @media (max-width: 768px) {
                    width: 119px;
                    height: 30px;
                    background-image: url(~@/assets/images/minting-popup/mobile/logo.png);
                }
            }
            h2 {
                font-weight: 700;
                font-size: 28px;
                line-height: 48px;
                letter-spacing: -0.04em;
                color: #FFF;
                margin-bottom: 10px;
                z-index: 10;
                @media (max-width: 768px) {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 36px;
                    letter-spacing: -0.02em;
                }
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: -0.02em;
                text-align: center;
                color: #FFF;
                margin-bottom: 20px;
                z-index: 10;
                @media (max-width: 768px) {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: -0.02em;
                }
            }
            .membership-popup-modal-image-wrap {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 303.77px;
                height: 440.47px;
                background-image: url(~@/assets/images/minting-popup/pc/main-image.png);
                margin: 0 auto 35px auto;
                z-index: 0;
                @media (max-width: 768px) {
                    width: 288px;
                    height: 417px;
                    background-image: url(~@/assets/images/minting-popup/mobile/main-image.png);
                }
            }
            .button-wrap {
                z-index: 10;
                position: relative;
                @media (max-width: 768px) {
                    margin-top: -55px;
                }
                .minting-popup-btn {
                    width: 200px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: -0.02em;
                    @media (max-width: 768px) {
                        width: 100%;
                    }
                }
            }
        }

    }*/

    .airdrop-modal-wrap {
        .inner-content {
            width: 540px;
            background: #FFF;
            border: 10px solid $primary;
            border-radius: 10px;
            padding: 40px 25px !important;
            @media (max-width: 768px) {
                width: 100% !important;
            }
            .logo-wrap {
                width: 158px;
                height: 40px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(~@/assets/images/minting-popup/pc/logo.png);
                margin: 0 auto 10px auto;
            }

            h2 {
                align-items: center;
                text-align: center;
                letter-spacing: -0.04em;
                font-weight: 700;
                font-size: 28px;
                line-height: 48px;
                color: $primary;
                @media (max-width: 768px) {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 36px;
                    letter-spacing: -0.02em;
                }
            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: -0.02em;
                @media (max-width: 768px) {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: -0.02em;
                }
            }

            @media (max-width: 768px) {
                .airdrop-modal-btn {
                    width: 100%;
                }
            }
        }
    }
</style>
