<template>
    <div class="home-main-wrap">
        <div class="home-main-back-color-wrap">
            <div class="home-main-inner-wrap">
                <div class="home-main-content-wrap">
                    <p class="pc-title">세계 최초의 혁신적인 파인 와인 NFT 거래소,Winex</p>
                    <h3 class="content">
                        Innovatory<br/>
                        Fine Wine NFT<br/>
                        Exchange Platform<br/>
                    </h3>
                    <p class="mobile-title">세계 최초의 혁신적인 파인 와인 NFT 거래소,Winex</p>
                    <router-link class="winex-btn primary-btn h40 w120" :to="{path: `/about/intro`}" style="display: block;">더보기</router-link>
                </div>
            </div>
        </div>

        <div class="home-main-bottom-wrap">

        </div>

        <div class="home-main-wine-wrap">
            <div class="home-main-inner-wrap">
                <div class="home-sample-wine-1"></div>
                <div class="home-sample-wine-2"></div>
                <div class="home-sample-wine-3"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Main"
    }
</script>

<style scoped lang="scss">
@import "@/assets/scss/homeMain.scss";
</style>
